<template>
    <div class="portrait flex-container" :class="[{'component-active': isActive}]" 
         @click="updateCurrentComponent">
        <image-upload :component="component" :options="{model: 'id', text:'addImageText'}"/>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'Portrait', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        caption:{
            get(){
                return this.component.caption[this.locale]
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'caption',this.locale)
            }
        }
    }, 
}
</script>
<style lang="less" scoped>

.component-active{
    box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
}
.portrait {
        cursor: pointer;
        height: 350px;
        width: 224px;
        flex-direction: column;
        padding: 16px;
        box-sizing: border-box;


        input{
            margin-top: auto;
            width: 192px;
            border: 1px solid #CFCFCF;
            background-color: #FFFFFF;
            height: 40px;
            resize: none;
            color: rgba(0,0,0,0.6);
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-style: italic;
            letter-spacing: 0.4px;
            line-height: 16px;
            padding-left: 16px;
            box-sizing: border-box;
        }
        :global(.image-upload){
            max-width: 100%;
            width: 100%;
            height: 100%;
            flex-direction: column;
            margin: auto;
            align-content: center;
            :global(.image){
                max-height: 120px;
                max-width: 100px;
                order:1;
                margin: 0 auto;
                margin-top: 70px;


            }

            :global(.delete-icon){
                margin-right: 8px;
                margin-top: 8px;
                order: 0;
            }
            :global(span){
                order:2;
                width: 80px;
                text-align: center;
                margin: 0 auto;
                margin-top: 8px;
            }
            
        }
    }
</style>


