var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "portrait flex-container",
      class: [{ "component-active": _vm.isActive }],
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("image-upload", {
        attrs: {
          component: _vm.component,
          options: { model: "id", text: "addImageText" }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }