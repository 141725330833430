var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-card",
      class: [{ "component-active": _vm.isActive }],
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("div", { staticClass: "row ml-0 mr-0" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          attrs: {
            type: "text",
            placeholder: _vm.$t("characterNamePlaceholder")
          },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.period,
              expression: "period"
            }
          ],
          attrs: { type: "text", placeholder: _vm.$t("CharacterPeriodOfTime") },
          domProps: { value: _vm.period },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.period = $event.target.value
            }
          }
        })
      ]),
      _c("ckeditor", {
        attrs: {
          editor: _vm.editor,
          placeholder: _vm.$t("biographyMainTextPlaceHolder"),
          config: _vm.editorConfig
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }