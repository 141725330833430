<template>
    <div class="main-ilustration flex-container" :class="[{'component-active': isActive}]" 
         @click="updateCurrentComponent" >
         <span>{{$t('mainIlustrationTag')}}</span>
        <image-upload :component="component.image" :options="{text:'addImageText', model:'id'}"/>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'MainIlustration', 
    mixins:[BackOfficeComponentMixin], 

}
</script>
<style lang="less" scoped>
    .main-ilustration{
        cursor: pointer;
        flex-direction: column;
        max-height: 264px;
        width: 464px;
        margin-left: 16px;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        span{
            margin: 0 auto;
            white-space: normal;
            height: auto;
        }
        :global(.image-upload){
            margin: auto;
        }
        :global(button){
            width: 237px;
        }

        .text-content{
            min-width: 100%;
            :global(input){
                width: 100%;
            }
        }


        .image-upload{
            width: inherit;
            height: inherit;
            max-width: 100%;
            max-height: 65%;
            flex-direction: column;
            margin: auto;
            margin-top: auto;
            align-content: center;
            :global(.image){
                max-height: 88px;
                max-width: 88px;
                margin-top: 30px;
                order:1;
            }

        :global(.delete-icon){
            margin-right: 8px;
            margin-top: 8px;
            order: 0;
        }
        :global(span){
            order:2;
            width: 80px;
            text-align: center;
            margin-top: 8px;
        }
    }

        
    }
</style>
