var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _vm.biography
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "components-container column custom-scrollbar" },
              [
                _c(
                  "div",
                  { staticClass: "biography-content flex-container" },
                  [
                    _c("portrait-component", {
                      attrs: { component: _vm.portrait }
                    }),
                    _c("main-card", { attrs: { component: _vm.mainCard } }),
                    _c("complementary-content", {
                      attrs: { component: _vm.complementaryContent }
                    }),
                    _c("main-ilustration", {
                      attrs: { component: _vm.mainIlustration }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.components, function(component, index) {
                  return _c(component.paragraph.type, {
                    key: index,
                    tag: "component",
                    attrs: { component: component }
                  })
                })
              ],
              2
            ),
            _c("properties-panel", {
              attrs: { "save-component": _vm.saveBiography }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }