var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "biography-layout row" }, [
            _c("div", { staticClass: "portrait column" }, [
              _c("div", { staticClass: "field-placeholder row" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("portraitTag")))])
              ]),
              _c("div", { staticClass: "field-placeholder" })
            ]),
            _c("div", { staticClass: "main-card column" }, [
              _c("div", { staticClass: "field-placeholder" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("mainCardTag")))])
            ]),
            _c("div", { staticClass: "complementary-content row ml-0 mr-0" }, [
              _c("span", [_vm._v(_vm._s(this.$t("complementaryText")))])
            ]),
            _c("div", { staticClass: "main-ilustration" }, [
              _c("div", { staticClass: "field-placeholder column" }, [
                _c("span", [_vm._v(_vm._s(this.$t("mainIlustrationTag")))])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "components-container column" },
            [
              _vm.components
                ? _c(
                    "draggable",
                    {
                      staticClass: "draggable-section",
                      attrs: { options: _vm.dragOptions, move: _vm.onMove },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.components,
                        callback: function($$v) {
                          _vm.components = $$v
                        },
                        expression: "components"
                      }
                    },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "flip-list", tag: "ul" } },
                        _vm._l(_vm.components, function(component, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c("biography-component", {
                                attrs: { component: component }
                              })
                            ],
                            1
                          )
                        })
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("components-catalog")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }