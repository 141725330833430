<template>
    <div class="biography-builder builder custom-scrollbar">
        <component :is="currentSection"/>
    </div>    
</template>
<script>
import BiographyBuilderLayoutSection from '@/components/BackOffice/BiographyBuilder/BiographyBuilderLayoutSection.vue'
import BiographyBuilderContentSection from '@/components/BackOffice/BiographyBuilder/BiographyBuilderContentSection.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'

export default {
    name: 'BiographyBuilder',
    mixins:[BuilderMixin], 
    components:{
        'biography-layout-section': BiographyBuilderLayoutSection, 
        'biography-content-section': BiographyBuilderContentSection
    },
    computed:{
        currentSection(){
            return this.$store.state.isContentSectionActive
                 ? 'biography-content-section' : 'biography-layout-section'
        }
    }, 
    created(){
        this.$store.commit('updateLoadingStatus', true)
        this.$store.dispatch('loadBiographyBuilderComponents', this.$route.params.biographyId)
        this.$store.commit('updateSwitchButtonDisplayStatus', true)
    }, 
    mounted(){
        this.$store.commit('updateLoadingStatus', false)
    }

}
</script>
<style lang="less" scoped>

</style>



