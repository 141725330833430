var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "complementary-content flex-container",
      class: [{ "component-active": _vm.isActive }],
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("complementaryText")))]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.complementaryText,
            expression: "complementaryText"
          }
        ],
        staticClass: "custom-scrollbar",
        attrs: {
          cols: "30",
          rows: "10",
          placeholder: _vm.$t("complementaryText") + "..."
        },
        domProps: { value: _vm.complementaryText },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.complementaryText = $event.target.value
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }