<template>
    <div class="complementary-content flex-container" :class="[{'component-active': isActive}]" 
         @click="updateCurrentComponent">
         <span>{{$t('complementaryText')}}</span>
          <textarea  cols="30" rows="10" class="custom-scrollbar" 
          v-model="complementaryText" :placeholder="$t('complementaryText')+'...'"></textarea>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'ComplementaryContent', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        complementaryText:{
            get(){
                return this.component.text[this.locale]
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'text', this.locale)
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .complementary-content{
        cursor: pointer;
        max-height: 264px;
        width: 224px;
        margin-top: 16px;
        box-sizing: border-box;
        flex-direction: column;
        padding: 16px;
        box-sizing: border-box;

        span{
            white-space: normal;
            height: 90px;
            width: 193px;
            font-size: 19px;
            text-align: center;
            margin: auto;
        }
        textarea{
            height: 100%;
            width: 100%;
            margin-top: 11px;
        }
    }

</style>
